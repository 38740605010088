import React, { useState } from 'react';
import { Referee } from '../Types/referee';
import JobInterestForm from './JobInterestForm';

interface ICompletedProps {
    referee: Referee;
}

const Completed: React.FC<ICompletedProps> = ({ referee: initialReferee }) => {
    const [referee, setReferee] = useState(initialReferee);
    const [showJobInterestForm, setShowJobInterestForm] = useState(true);

    const handleJobInterestSubmit = (updatedReferee: Referee) => {
        setReferee(updatedReferee);
        setShowJobInterestForm(false);
    };

    return (
        <div className="px-3 lg:px-5 flex flex-col h-screen max-w-6xl space-y-12 lg:space-y-20 m-6 md:m-16 lg:mx-auto">
            <div>
                <img className="h-12 lg:h-20" src={referee.orgLogoUrl} alt="Uniting" />
            </div>
            <h1 className="text-3xl lg:text-4xl text-left font-extrabold">Thanks for submitting a reference for {referee.candidateName}!</h1>
            <div>
                <h2 className="text-lg mb-4 font-bold">What happens now?</h2>
                <p className="text-base text-left max-w-2xl">
                    If there is anything in the information you provided that we wish to discuss further, we will contact you.
                </p>
            </div>

            {referee.showJobInterestOption && showJobInterestForm && !referee.isInterestedInJob && (
                <JobInterestForm
                    referee={referee}
                    onSubmit={handleJobInterestSubmit}
                />
            )}

            {referee.isInterestedInJob && (
                <div className="text-base text-left max-w-2xl">
                    <p className="font-bold text-green-600">
                        Thank you for your interest! Our recruitment team will review your information and get in touch if there are suitable opportunities.
                    </p>
                </div>
            )}
        </div>
    );
};

export default Completed;
