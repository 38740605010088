export interface Referee {
    id: string;
    orgName: string;
    orgLogoUrl: string;
    name: string;
    jobTitle: string;
    candidateName: string;
    status: ReferenceCheckStatus;
    percentCompleted: number;
    createdAt: Date;
    lastActive: Date;
    welcomeMessage: string;
    welcomeMessageAcknowledged: boolean;
    questions: Question[];
    conversation: ChatCompletionRequestMessage[];
    isInterestedInJob?: boolean;
    jobInterestNotes?: string;
    showJobInterestOption?: boolean;
}

export interface Question {
    question: string;
    status: QuestionStatus;
    attempts: number;
}

export enum QuestionStatus {
    Pending = "pending",
    Current = "current",
    Answered = "answered",
    Skipped = "skipped"
}

export enum ReferenceCheckStatus {
    Pending = "pending",
    InProgress = "in_progress",
    Completed = "completed",
    Abandoned = "abandoned",
    Cancelled = "cancelled"
}

export interface ChatCompletionRequestMessage {
    'role': string
    'content'?: string;
    'name'?: string;
}