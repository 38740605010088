import React, { useState } from 'react';
import apiService from '../Services/apiService';
import { Referee } from '../Types/referee';

interface JobInterestFormProps {
    referee: Referee;
    onSubmit: (updatedReferee: Referee) => void;
}

const JobInterestForm: React.FC<JobInterestFormProps> = ({ referee, onSubmit }) => {
    const [notes, setNotes] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            const updatedReferee = await apiService.updateJobInterest(referee.id, true, notes);
            onSubmit(updatedReferee);
        } catch (error) {
            console.error('Error submitting job interest:', error);
        }
        setIsSubmitting(false);
    };

    const handleDecline = async () => {
        try {
            const updatedReferee = await apiService.updateJobInterest(referee.id, false);
            onSubmit(updatedReferee);
        } catch (error) {
            console.error('Error declining job interest:', error);
        }
    };

    return (
        <div className="mt-8 max-w-2xl">
            <h2 className="text-lg font-bold mb-4">Would you be interested in similar roles yourself?</h2>
            <textarea
                className="w-full px-4 py-2 border rounded-md mb-4"
                rows={4}
                placeholder="Tell us a bit about yourself and what you're looking for..."
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
            />
            <div className="flex space-x-4">
                <button
                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500 disabled:bg-gray-400"
                    onClick={handleSubmit}
                    disabled={isSubmitting || !notes.trim()}
                >
                    Submit Interest
                </button>
                <button
                    className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
                    onClick={handleDecline}
                >
                    No, thanks
                </button>
            </div>
        </div>
    );
};

export default JobInterestForm;